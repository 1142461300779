<template>
  <div class="nvx-app-container" v-if="device">
    <div class="theft-header">
      <icon-wrapper :iconType="'icon-bg-theft48'" />
      <h2>{{ $t('theft_file_title_fmt', {name: device.name}) }}</h2>
    </div>

    <span v-html="$t('theft_footer')"></span>

    <nvx-list>
        
        <nvx-section v-if="showRealtimeBoost" :header="'theft_file_subtitle_1'" :footer="'theft_realtime_boost_help'">
          <nvx-button-cell :target="realtimeBoosUniversalLink" :icon="'icon-live-tracking24'" :class="'nvx-rtb'">
            {{ $t('theft_activate_realtime_boost') }}
          </nvx-button-cell>
        </nvx-section>
        
        <nvx-section :header="'theft_file_subtitle_2'">
          <nvx-button-cell :target="getReport" :icon="'icon-doc'">
            <div>
              {{ $t('generate_police_report') }}
              <div class="button-tag">
                <icon-wrapper :iconType="'icon-qr-code'" class="hack-fill" />
                {{ $t('theft_file_police_tag') }}
              </div>
            </div>
          </nvx-button-cell>
          
          <nvx-router-cell :to="'/app/public_links?serial=' + device.serial" :icon="'icon-share'">
              {{ $t('share_link_button') }}
          </nvx-router-cell>
        </nvx-section>

        <nvx-section v-if="isFindMy" :header="'theft_file_subtitle_3'" :footer="'theft_findmy_help'">
          <nvx-button-cell :target="findMyUniversalLink" :icon="'icon-proximity24'">
            {{ $t('theft_findmy') }}
          </nvx-button-cell>
        </nvx-section>

    </nvx-list>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {getTheftForms}  from '@/api/devices.api'
import {QUERYPARAMS, getAppOs} from '@/api/appcompat.api'
import IconWrapper from "@/components/ui/IconWrapper.vue";
import NvxSection from "@/components/ui/NvxSection.vue";
import NvxList from "@/components/ui/NvxList.vue";
import NvxButtonCell from "@/components/ui/NvxCell.vue";
import NvxRouterCell from "@/components/ui/NvxRouterCell.vue";

const feather = require('feather-icons')

export default {
  name: 'TheftFile',
  
  components: {
    IconWrapper,
    NvxSection,
    NvxList,
    NvxButtonCell,
    NvxRouterCell,
  },

  data() {
    return {
      device: null,
      reportFile: null,
      userOS: null,
    }
  },
  
  computed: {
    ...mapGetters("devices", ["getDevices", "getSelectedDevice"]),
    ...mapGetters("appcompat", ["isFindMy", "isRealtimeBoost"]),

    fileName(){
      return 'report_'+ this.device.serial.slice(-6)+'.pdf';
    },
    showRealtimeBoost() {
      if (this.$options.filters.format_device_type(this.device) == "Tracker LWT3") {
        return this.isRealtimeBoost
      }
      return false
    }
  },
  
  methods: {
    ...mapActions('devices', ['fetchDevices', 'setSelectedDevice']),
    ...mapActions('devices', ['removeDevices']),
    ...mapActions('devices', ['removeSelectedDevice']),
    ...mapActions("users", ["setToken"]),
    ...mapActions("appcompat", ["setHostAppVersion", "setDeeplinkCapability", "setHostOS"]),

    getReport() {
      getTheftForms(this.device.id)
      .then((re) => {
        const blob = new Blob([re.data], { type: "application/pdf" });
        const file = new File([blob], this.fileName , { type: 'application/pdf' });
        this.reportFile = file;
        this.shareFile();
      })
      .catch(console.error)
    },


    findMyUniversalLink() {
      window.location.href = '/app/native/findmy?serial=' + this.device.serial;
    },

    realtimeBoosUniversalLink() {
      window.location.href = '/app/native/rtb?serial=' + this.device.serial;
    },

    async shareFile() {

      if (this.userOS == 'Android') {
        // eslint-disable-next-line
        if (NvxAndroid.shareDataUrl != null) {
          try {
            const stringFile = await this.base64File(this.reportFile)
            new Promise((resolve) => {
              // eslint-disable-next-line
              NvxAndroid.shareDataUrl(this.reportFile.name, "fileDataAsURL", stringFile)
              resolve()
            });
          } catch (err) {
            console.log(err)
            this.loading = false;
            this.fileError = true;
          }
        }
      }
      else {
        await navigator.share({
          files: [this.reportFile],
          title: this.reportFile.name,
        })
        .then((re) => {
          console.log('thanks')
        })
        .catch(console.error)
      }

    },

    async base64File(file) {
      
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    // FIXME: move this function in store/device.module.js for future use
    icon_path(icon_id){
      let myIcons = ['pin', 'sacamain', 'malette', 'valise', 'malette', 'sacados', 'valise', 'velo', 'bateau', 'bateau', 'voiture', 'voiture', 'caravane', 'diable', 'chien', 'kayak', 'laptop', 'moto', 'helicoptere', 'avion', 'avion', 'scooter', 'scooter', 'tente', 'camion', 'camion', 'tracteur', 'camion', 'camion', 'camion', 'chien', 'chat', 'garcon', 'cube', 'fille', 'moto', 'tractopelle', 'animal', 'femme', 'homme', 'trottinette', 'bee', 'carpark', 'gps', 'health', 'homeappliance', 'keys', 'tv', 'phone', 'appareilphoto', 'poussette']
      if (icon_id >= 0 && icon_id <= 50 && !this.device.tracker_config.image) {
        return "/images/icons/" + myIcons[icon_id] + '.png';
      }
      return "/images/icons/cube.png";
    },
    
  },

  async mounted() {
    window.zE('webWidget', 'hide');
    this.userOS = getAppOs(navigator, window);
    this.setHostOS(this.userOS);
  },
  
  async created() {
    let parameters = this.$route.query;
    if (parameters["token"]) {
      let token = parameters["token"];
      this.setToken(token);
    }
    if (parameters[QUERYPARAMS.deepSupport]) {
      let capability = parameters[QUERYPARAMS.deepSupport];
      this.setDeeplinkCapability(capability);
    }
    await this.removeDevices();
    await this.removeSelectedDevice();
    await this.fetchDevices();
    if (parameters["serial"]) {
      await this.setSelectedDevice(this.getDevices.filter((device) => device.serial == parameters["serial"])[0]);
    }
    if (this.getSelectedDevice === null && this.getDevices.length != 0) {
      await this.setSelectedDevice(this.getDevices[0]);
    }
    this.device = this.getSelectedDevice
    
  },
  updated() {
    feather.replace()
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
@import "@/assets/scss/appmenus/native.scss";

.theft-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 1rem;
  gap: 1rem;
}

</style>
